import { LogoClickIcon } from '../../../assets/icons/icons';
import './About.css';

function About() {
    return (
        <div className='about'>
            <div className='about__logo-box'>
                <div className='about__text-logo-box'>
                    <p className='about__logo-alclic'>ALCLIC</p>
                    <p className='about__logo-ru'>.ru</p>
                </div>

                <LogoClickIcon
                    className={'about__logo-icon'}
                    fillClassName={''}
                />
            </div>
            <p className='about__text'>Наша компания имеет большой опыт в&nbsp;помощи получения, продления, переоформления лицензии с&nbsp;2015&nbsp;года. За&nbsp;это время мы&nbsp;помогли более 100 юридическим организациям. Мы&nbsp;гарантируем 100% возврат аванса в&nbsp;случае не&nbsp;получения лицензии. А&nbsp;также предоставляем бесплатную консультация при начальном этапе сотрудничества. Звоните!</p>
        </div>
    );
}

export default About