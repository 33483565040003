
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';

import './AddressMap.css';
import { LogoClickIcon, MapAddressIcon, MapPhoneIcon, MapScheduleIcon } from '../../../assets/icons/icons';
import { MAIN_ADDRESS, MAIN_CONTACT_PHONE, MAIN_WORKTIME } from '../../../assets/utils/constants';



function AddressMap() {
  const defaultState = {
    center: [55.742718, 37.527430],
    zoom: 16,
  };

  return (
    <div className='address-map'>
      <div className='address-map__container'>
        <div className='address-map__ymap-container'>
          <YMaps>
            <Map width={'100%'} height={'100%'} defaultState={defaultState}>
              <Placemark geometry={defaultState.center}

                options={{
                  iconColor: '#FF6B6B',
                  preset: "islands#icon",
                }}
              />
            </Map>
          </YMaps>
        </div>

        <div className='address-map__info'>
          <div className='address-map__info-item'>
            <div className='address-map__info-item-key'>
              <MapAddressIcon className={'address-map__info-item-key-icon'} />
              <p className='address-map__info-item-key-name'>Адрес</p>
            </div>
            <a className='address-map__info-item-value' href={MAIN_ADDRESS.link} target='_blanc' rel='norefferer'>{MAIN_ADDRESS.display_value}</a>
          </div>
          <div className='address-map__info-item'>
            <div className='address-map__info-item-key'>
              <MapPhoneIcon className={'address-map__info-item-key-icon'} />
              <p className='address-map__info-item-key-name'>Телефон</p>
            </div>
            <a className='address-map__info-item-value' href={`tel:${MAIN_CONTACT_PHONE.number}`} target='_blanc' rel='norefferer'>{MAIN_CONTACT_PHONE.display_value}</a>
          </div>
          <div className='address-map__info-item'>
            <div className='address-map__info-item-key'>
              <MapScheduleIcon className={'address-map__info-item-key-icon'} />
              <p className='address-map__info-item-key-name'>Часы работы</p>
            </div>
            <p className='address-map__info-item-value'>{MAIN_WORKTIME}</p>
          </div>
        </div>
      </div>

    </div>
  );
}

export default AddressMap