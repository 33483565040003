export function ObtainingIcon({ className }) {
    return (
        <svg
            width={176}
            height={102}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            viewBox="0 0 176 102"
        >
            <g clipPath="url(#prefix__clip0_548_1253)">
                <path d="M218 144L74 0v144h144z" fill="#EDE765" />
                <path
                    d="M0 10C0 4.477 4.477 0 10 0h64.24L116 41.786V120c0 5.523-4.477 10-10 10H10c-5.523 0-10-4.477-10-10V10z"
                    fill="#fff"
                />
                <path
                    opacity={0.2}
                    d="M116 75.447L79.424 38.685H97.33l18.669 3.1v33.662z"
                    fill="#93CD66"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M75.094 60.095a4.645 4.645 0 01.212 6.563l-17.4 18.571a4.638 4.638 0 01-6.77 0L40.694 74.086a4.645 4.645 0 01.211-6.562 4.638 4.638 0 016.559.211l7.055 7.53 14.016-14.958a4.638 4.638 0 016.558-.212z"
                    fill="#71E298"
                />
                <path
                    d="M74.24 0l41.759 41.786h-35.76a6 6 0 01-6-6V0z"
                    fill="#77DCCA"
                />
            </g>
            <defs>
                <clipPath id="prefix__clip0_548_1253">
                    <path fill="#fff" d="M0 0h176v102H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}

export function RenewalIcon({ className }) {
    return (
        <svg
            width={229}
            height={102}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            viewBox="0 0 229 102"
        >
            <g clipPath="url(#prefix__clip0_548_1321)">
                <path d="M271 144L127 0v144h144z" fill="#6BD0E9" />
                <path
                    d="M-.5 10c0-5.523 4.477-10 10-10h117.74L169 41.786V120c0 5.523-4.477 10-10 10H9.5c-5.523 0-10-4.477-10-10V10z"
                    fill="#fff"
                />
                <path
                    opacity={0.2}
                    d="M169 75.447l-36.576-36.762h17.907l18.669 3.1v33.662z"
                    fill="#6683CD"
                />
                <path
                    d="M127.239 0l41.76 41.786h-35.76a6 6 0 01-6-6V0z"
                    fill="#86C5FF"
                />
                <path
                    d="M107.768 68.768a2.5 2.5 0 000-3.536l-15.91-15.91a2.5 2.5 0 00-3.536 3.536L102.464 67 88.322 81.142a2.5 2.5 0 003.536 3.536l15.91-15.91zM61 69.5h45v-5H61v5z"
                    fill="#FFBF1A"
                />
            </g>
            <defs>
                <clipPath id="prefix__clip0_548_1321">
                    <path fill="#fff" d="M0 0h229v102H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}


export function ReissueIcon({ className }) {
    return (
        <svg
            width={265}
            height={107}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            viewBox="0 0 265 107"
        >
            <g clipPath="url(#prefix__clip0_548_1385)">
                <rect x={66} width={100} height={130} rx={10} fill="#CFD4FF" />
                <path
                    d="M116 35c0-5.523-4.477-10-10-10H41.76L0 66.786V145c0 5.523 4.477 10 10 10h96c5.523 0 10-4.477 10-10V35z"
                    fill="#fff"
                    fillOpacity={0.8}
                />
                <path
                    opacity={0.2}
                    d="M0 100.447l36.576-36.762H18.67l-18.67 3.1v33.662z"
                    fill="#AFB7FF"
                />
                <path d="M41.76 25L0 66.786h35.76a6 6 0 006-6V25z" fill="#C9CEFF" />
                <path d="M310 152L166 8v144h144z" fill="#9CA4F0" />
                <circle cx={114} cy={41} r={20} fill="#41E2BD" />
                <path
                    d="M114.903 31.833l3.056-3.056-3.056-3.056M113.1 50.168l-3.056 3.056 3.056 3.056"
                    stroke="#fff"
                    strokeWidth={2.6}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M122.67 32.4a12.17 12.17 0 013.554 8.6c0 6.75-5.473 12.224-12.224 12.224-1.2 0-2.357-.18-3.454-.503M105.222 49.498A12.179 12.179 0 01101.778 41c0-6.75 5.473-12.223 12.223-12.223 1.201 0 2.358.18 3.455.502"
                    stroke="#fff"
                    strokeWidth={2.6}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="prefix__clip0_548_1385">
                    <path fill="#fff" d="M0 0h265v107H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}


export function LeaseIcon({ className }) {
    return (
        <svg
            width={192}
            height={101}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            viewBox="0 0 192 101"
        >
            <g clipPath="url(#prefix__clip0_548_1466)">
                <path d="M207 113L71 2.5V113h136z" fill="#8CCEDD" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M131.376 56c1.882 0 2.723-2.363 1.263-3.55L69.526 1.056a4 4 0 00-5.052 0L1.361 52.449C-.1 53.637.74 56 2.624 56H9v45h116V56h6.376z"
                    fill="#fff"
                />
                <rect x={39} y={56} width={56} height={8} rx={4} fill="#7381A7" />
                <rect x={39} y={70} width={38} height={8} rx={4} fill="#7381A7" />
            </g>
            <defs>
                <clipPath id="prefix__clip0_548_1466">
                    <path fill="#fff" d="M0 0h192v101H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}


export function RoadmapDocsIcon({ className }) {
    return (
        <svg
            width={48}
            height={48}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            viewBox="0 0 48 48"
        >
            <path
                d="M5.25 13c0-5.523 4.477-10 10-10h14l13.5 13.5V35c0 5.523-4.477 10-10 10h-17.5c-5.523 0-10-4.477-10-10V13z"
                fill="#fff"
            />
            <path
                opacity={0.2}
                d="M42.75 27.375L30.926 15.498h5.789L42.75 16.5v10.875z"
                fill="#66CD89"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M29.526 24.03a1.5 1.5 0 01.069 2.12l-5.626 6a1.5 1.5 0 01-2.188 0l-3.375-3.6a1.5 1.5 0 112.188-2.05l2.281 2.432 4.531-4.833a1.5 1.5 0 012.12-.068z"
                fill="#71E298"
            />
            <path d="M29.25 3l13.5 13.5h-7.5a6 6 0 01-6-6V3z" fill="#77DCCA" />
        </svg>
    );
}

export function ServiceBottle1Icon({ className }) {
    return (
        <svg
            width={202}
            height={752}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            viewBox="0 0 202 752"
        >
            <path
                opacity={0.3}
                d="M159.862 233.485c-10.612-10.803-18.99-19.338-18.99-30.623V41.256l1.32-1.259c1.761-1.679 2.755-3.964 2.755-6.342V17.13c0-2.379-.994-4.664-2.755-6.343l-1.043-.995V1.865c0-1.026-.88-1.865-1.956-1.865H62.807c-1.076 0-1.956.84-1.956 1.865v7.928l-1.043.995c-1.76 1.68-2.755 3.964-2.755 6.343v16.508c0 2.379.994 4.664 2.755 6.343l1.32 1.259v161.605c0 11.286-8.379 19.82-18.99 30.624C24.354 251.595 0 276.389 0 331.108v378.143C0 752 25.772 752 34.232 752h133.536c8.46 0 34.232 0 34.232-42.749V331.108c0-54.719-24.353-79.513-42.138-97.623z"
                fill="url(#prefix__paint0_linear_562_1631)"
            />
            <defs>
                <linearGradient
                    id="prefix__paint0_linear_562_1631"
                    x1={101}
                    y1={116.556}
                    x2={101}
                    y2={752}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#45547C" />
                    <stop offset={1} stopColor="#45547C" stopOpacity={0} />
                </linearGradient>
            </defs>
        </svg>
    );
}

export function ServiceBottle2Icon({ className }) {
    return (
        <svg
            width={149}
            height={552}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            viewBox="0 0 149 552"
        >
            <path
                opacity={0.2}
                d="M117.918 171.388c-7.827-7.93-14.008-14.195-14.008-22.479V30.284l.974-.924c1.299-1.233 2.032-2.91 2.032-4.656v-12.13c0-1.745-.733-3.423-2.032-4.655l-.769-.73v-5.82c0-.753-.649-1.369-1.443-1.369H46.328c-.794 0-1.443.616-1.443 1.37v5.819l-.77.73c-1.298 1.232-2.031 2.91-2.031 4.656v12.118c0 1.745.733 3.423 2.032 4.655l.974.925v118.625c0 8.284-6.18 14.548-14.008 22.479C17.964 184.682 0 202.882 0 243.047v277.574C0 552 19.01 552 25.25 552h98.5c6.24 0 25.25 0 25.25-31.379V243.047c0-40.165-17.964-58.365-31.082-71.659z"
                fill="#2D4B9D"
            />
        </svg>
    );
}