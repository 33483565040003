import { Bottle1Icon, Bottle2Icon, Bottle3Icon } from '../../assets/icons/main';
import ContactForm from '../ContactForm/ContactForm';
import About from './About/About';
import Advantages from './Advantages/Advantages';
import Banner from './Banner/Banner';
import Danger from './Danger/Danger';
import './Main.css';
import AddressMap from './AddressMap/AddressMap';

import Process from './Process/Process';
import Services from './Services/Services';
import Footer from '../Footer/Footer';
import { Helmet } from 'react-helmet';

function Main() {
    return (
        <div className='main'>
            <Helmet>
                <title>Alclic – Алкогольная Лицензия</title>
            </Helmet>
            <Bottle1Icon
                className={'main__bg-bottle-1'}
            />
            <Bottle2Icon
                className={'main__bg-bottle-2'}
            />
            <Banner />
            <Advantages />
            <Services />
            <Danger />
            <Process />
            <ContactForm
                className={'main__contact-form'}
            />
            <div className='main__box'>
                <Bottle3Icon
                    className={'main__bg-bottle-3'}
                />
                <About />
                <AddressMap />
                <Footer />
            </div>
        </div >
    );
}

export default Main