import { MAIN_URL } from "../utils/constants";

class MainApi {
  // constructor({ baseUrl }) {
  //   MAIN_URL = baseUrl;
  // }

  _checkResponse(res) {
    if (res.ok) {
      return res.json();
    } else {
      const statusCode = res.status;
      return res.json().then((res) => {
        return Promise.reject({
          statusCode: statusCode,
          message: res.msg,
          detail: res.detail,
        });
      });
    }
  }

  _checkResponseWithCookies({ res, func, params }) {
    if (res.ok) {
      return res.json();
    } else {
      const statusCode = res.status;
      return res.json().then((res) => {
        if (statusCode === 422 && res.detail === "Signature has expired") {
          console.log("ss");
          return mainApi
            .refreshJWT()
            .then((res) => {
              return func(params);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        } else {
          return Promise.reject({
            statusCode: statusCode,
            message: res.msg,
            detail: res.detail,
          });
        }
      });
    }
  }

  refreshJWT() {
    return fetch(`${MAIN_URL}/users/refresh-jwt`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(this._checkResponse);
  }

  

  sendContactForm({ name, phone, type}) {
    return fetch(`${MAIN_URL}/landing/send-form`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: name,
        phone: phone,
        type: type,
      }
      )
    }).then(this._checkResponse)
  };

 
  async sendReview({ data }) {
    return fetch(`${MAIN_URL}/reviews/send`, {
      method: "POST",
      credentials: "include",
      body: data,
    }).then(this._checkResponse);
  }
}


const mainApi = new MainApi({
  baseUrl: MAIN_URL,
});

export default mainApi;
