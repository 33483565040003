import { useState } from 'react';
import { CheckboxIcon, ContactIcon } from '../../assets/icons/icons';
import './ContactForm.css';
import Input from './Input/Input';
import MiniPreloader from '../MiniPreloader/MiniPreloader';
import useWindowSize from '../../assets/hooks/useWindowSize';
import SuccessPopup from './SuccessPopup/SuccessPopup';
import mainApi from '../../assets/api/MainApi';
import { getServiceName } from '../../assets/utils/utils';

function ContactForm({ className, service_key }) {

    const windowSize = useWindowSize()

    const [form, setForm] = useState({
        name: '',
        phone: '',
        accept_use_of_data: false,
    })

    const [formValid, setFormValid] = useState({
        phone: {},
    })


    function handleFormChange(e) {
        const value = e.target.value
        const name = e.target.name

        switch (name) {

            case 'phone':
                let inputValue = e.target.value.replace(/\D/g, '')
                let formattedInputValue = '';
                if (!inputValue) {
                    setForm(prevValue => ({
                        ...prevValue,
                        phone: '',
                    }))
                    setFormValid(prevValue => ({
                        ...prevValue,
                        phone: {
                            errorMassage: 'Можно вводить только цифры',
                            validState: false
                        },
                    }))
                }
                else {
                    if (['7', '8', '9'].indexOf(inputValue[0]) > -1) {
                        setFormValid(prevValue => ({
                            ...prevValue,
                            phone: {
                                errorMassage: '',
                                validState: false
                            },
                        }))
                        if (inputValue[0] === '9') inputValue = '7' + inputValue;

                        let firstSimbols = (inputValue[0] === '8') ? '8' : '+7';
                        formattedInputValue = firstSimbols + ' '

                        if (inputValue.length > 1) {
                            formattedInputValue += '(' + inputValue.substring(1, 4)
                        }
                        if (inputValue.length >= 5) {
                            formattedInputValue += ') ' + inputValue.substring(4, 7)
                        }
                        if (inputValue.length >= 8) {
                            formattedInputValue += '-' + inputValue.substring(7, 9)
                        }
                        if (inputValue.length >= 10) {
                            formattedInputValue += '-' + inputValue.substring(9, 11)
                        }
                        if (inputValue.length >= 11) {
                            setFormValid(prevValue => ({
                                ...prevValue,
                                phone: {
                                    errorMassage: '',
                                    validState: true
                                },
                            }))
                        } else {

                            setFormValid(prevValue => ({
                                ...prevValue,
                                phone: {
                                    errorMassage: '',
                                    validState: false
                                },
                            }))
                        }
                    } else {
                        formattedInputValue = '+' + inputValue.substring(0, 15)
                        if (inputValue.length >= 11) {
                            setFormValid(prevValue => ({
                                ...prevValue,
                                phone: {
                                    errorMassage: '',
                                    validState: true
                                },
                            }))
                        } else {
                            setFormValid(prevValue => ({
                                ...prevValue,
                                phone: {
                                    errorMassage: '',
                                    validState: false
                                },
                            }))
                        }
                    }


                    setForm(prevValue => ({
                        ...prevValue,
                        phone: formattedInputValue,
                    }))
                }
                break;

            default:
                setForm(prevValue => ({
                    ...prevValue,
                    [name]: value,
                }))
                break;
        }

    }

    function handlePhoneDelite(e) {
        if (e.keyCode === 8 && e.target.value.replace(/\D/g, '').length === 1) {
            setForm(prevValue => ({
                ...prevValue,
                phone: '',
            }))
        }
        if (e.keyCode === 8 && e.target.value.replace(/\D/g, '').length < 11) {
            // setForm({
            //     errorMassage: '',
            //     validState: false
            // });
        }

    }

    const isValid = form.name && form.accept_use_of_data && form.phone ? formValid.phone.validState : false
    const [isSuccesPopopOpen, setSuccesPopupOpen] = useState(false)
    const [isPreloaderVisible, setPreloaderVisible] = useState(false)
    function handleSubmit() {
        setPreloaderVisible(true)
        mainApi.sendContactForm({
            name: form.name,
            phone: form.phone && formValid.phone.validState ? form.phone.replace(/\D/g, '') : '',
            type: service_key ? getServiceName(service_key) : null,
        })
            .then((res) => {
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setForm({
                    name: '',
                    phone: '',
                    accept_use_of_data: false,
                })
                setFormValid({
                    phone: {},
                })
                setPreloaderVisible(false)
                setSuccesPopupOpen(true)
            })
    }


    return (
        <div className={`contact-form ${className ? className : ''}`}>
            <SuccessPopup isOpen={isSuccesPopopOpen} handleClose={() => {
                setSuccesPopupOpen(false)
            }} />
            <div className='contact-form__container'>
                <ContactIcon
                    className={'contact-form__icon contact-form__icon_pc'}
                />
                <div className='contact-form__info'>
                    <div className='contact-form__title-box'>
                        <ContactIcon
                            className={'contact-form__icon contact-form__icon_mobile'}
                        />
                        <h4 className='contact-form__title'>Оставьте заявку <br />на&nbsp;бесплатную консультацию</h4>
                    </div>

                    <p className='contact-form__text'>Заполните форму, чтобы получить консультацию специалиста, а&nbsp;также стоимость и&nbsp;сроки получения документов для вашей деятельности.</p>
                    <div className='contact-form__inputs-and-btn'>
                        <div className='contact-form__inputs'>
                            <Input
                                index={1}
                                label="Имя"
                                value={form.name}
                                name="name"
                                type="text"
                                handleChange={handleFormChange}
                                isRequired={true}
                            />
                            <Input
                                index={1}
                                label="Номер телефона"
                                value={form.phone}
                                name="phone"
                                type="text"
                                handleChange={handleFormChange}
                                isRequired={true}
                                onKeyDown={handlePhoneDelite}
                            />
                        </div>
                        {windowSize.width > 750 ?
                            <button className={`contact-form__submit ${isValid ? 'contact-form__submit_active' : ''} contact-form__submit_pc`} type='button' onClick={handleSubmit}>
                                {isPreloaderVisible ? <MiniPreloader isLinkColor={true} /> : 'Оставить заявку'}
                            </button>
                            : null}
                    </div>
                    <div className='contact-form__checkbox-container'>
                        <button className={`contact-form__checkbox ${form.accept_use_of_data ? 'contact-form__checkbox_active' : ''}`} type='button' onClick={() => {
                            setForm(prevValue => ({
                                ...prevValue,
                                accept_use_of_data: !prevValue.accept_use_of_data,
                            }))
                        }}>
                            <div className='contact-form__checkbox-border'>

                            </div>
                            <CheckboxIcon
                                className={`contact-form__checkbox-icon ${form.accept_use_of_data ? 'contact-form__checkbox-icon_active' : ''}`}
                            />
                        </button>
                        <p className='contact-form__checkbox-text' onClick={() => {
                            setForm(prevValue => ({
                                ...prevValue,
                                accept_use_of_data: !prevValue.accept_use_of_data,
                            }))
                        }}>Я&nbsp;согласен на&nbsp;обработку персональных данных</p>
                        {windowSize.width <= 750 ?
                            <button className={`contact-form__submit ${isValid ? 'contact-form__submit_active' : ''} contact-form__submit_mobile`} type='button' onClick={handleSubmit}>
                                {isPreloaderVisible ? <MiniPreloader isLinkColor={true} /> : 'Оставить заявку'}
                            </button>
                            : null}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactForm