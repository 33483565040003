import { NavLink, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { LogoClickIcon, NavLinkIcon } from '../../assets/icons/icons';
import './ServicesPage.css';
import { LEASE_AGREEMENT, LICENSE_REISSUE, LICENSE_RENEWAL, OBTAINING_LICENSES, SERVICES_LINKS } from '../../assets/utils/constants';
import ServiceTemplate from './ServiceTemplate/ServiceTemplate';
import { useEffect, useState } from 'react';
import { ServiceBottle1Icon, ServiceBottle2Icon } from '../../assets/icons/services';
import ContactForm from '../ContactForm/ContactForm';
import Footer from '../Footer/Footer';


function ServicesPage() {

  const location = useLocation();

  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState("fade-in");

  useEffect(() => {
    if (location !== displayLocation) setTransistionStage("fade-out");
  }, [location, displayLocation]);

  return (
    <div className='services-page'>
      <ServiceBottle1Icon
        className={'services-page__bottle-1'}
      />
      <ServiceBottle2Icon
        className={'services-page__bottle-2'}
      />
      <div className='services-page__block-with-padding'>
        <div className='services-page__heading'>
          <div className='services-page__heading-texts'>
            <h2 className='services-page__heading-title'>Услуги</h2>
            <p className='services-page__heading-text'>Комплексная юридическая помощь в&nbsp;получении лицензии в&nbsp;Москве и&nbsp;Московской области</p>
          </div>
          <LogoClickIcon
            className={'services-page__heading-icon'}
            fillClassName={'services-page__heading-icon-fill'}
            secondaryFillClassName={'services-page__heading-icon-secondary-fill'}
          />
        </div>
      </div>

      <div className='services-page__block-with-padding services-page__block-with-padding_links'>
        <div className='services-page__links-and-content'>
          <div className='services-page__links'>
            {SERVICES_LINKS.map((item, i) => (
              <NavLink
                className={({ isActive }) =>
                  "services-page__link" + (isActive ? " services-page__link_active" : "")
                }
                key={`services-page__link${i}`}
                to={item.path}>
                <p className='services-page__link-text'>{item.title}</p>
                <NavLinkIcon
                  className={'services-page__link-icon'}
                  fillClassName={'services-page__link-icon-fill'}
                  secondaryFillClassName={'services-page__link-icon-secondary-fill'}
                />
              </NavLink>
            ))}
          </div>
          <div
            className={`services-page__content services-page__content_${transitionStage}`}
            onAnimationEnd={() => {
              if (transitionStage === "fade-out") {
                setTransistionStage("fade-in");
                setDisplayLocation(location);
              }
            }}>
            <Routes location={displayLocation}>
              <Route index path='/' element={<ServiceTemplate service={OBTAINING_LICENSES} />}></Route>
              <Route path='/obtaining-licenses' element={<ServiceTemplate service={OBTAINING_LICENSES} />}></Route>
              <Route path='/license-renewal' element={<ServiceTemplate service={LICENSE_RENEWAL} />}></Route>
              <Route path='/license-reissue' element={<ServiceTemplate service={LICENSE_REISSUE} />}></Route>
              <Route path='/lease-agreement' element={<ServiceTemplate service={LEASE_AGREEMENT} />}></Route>
            </Routes>
          </div>
        </div>
      </div>
      <ContactForm
        className={'services-page__contact-form'}
        service_key={location.pathname.split('/')[2]}
      />
      <Footer />
    </div>
  );
}

export default ServicesPage