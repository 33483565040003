export function Bottle1Icon({ className }) {
    return (
        <svg
            width={523}
            height={1942}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            viewBox="0 0 523 1942"
        >
            <path
                opacity={0.1}
                d="M413.901 602.964c-27.475-27.901-49.168-49.94-49.168-79.084V106.542l3.418-3.251c4.558-4.336 7.133-10.237 7.133-16.38V44.24c0-6.142-2.575-12.043-7.133-16.379l-2.701-2.57V4.818c0-2.65-2.279-4.817-5.065-4.817h-197.77c-2.786 0-5.065 2.168-5.065 4.817v20.474l-2.701 2.569c-4.558 4.336-7.133 10.237-7.133 16.379v42.633c0 6.142 2.575 12.043 7.133 16.378l3.418 3.252V523.84c0 29.144-21.693 51.183-49.168 79.083C63.054 649.731 0 713.761 0 855.068V1831.6C0 1942 66.725 1942 88.63 1942h345.74c21.904 0 88.63 0 88.63-110.4V855.068c0-141.307-63.054-205.337-109.099-252.104z"
                fill="url(#prefix__paint0_linear_510_1218)"
            />
            <defs>
                <linearGradient
                    id="prefix__paint0_linear_510_1218"
                    x1={261.5}
                    y1={301}
                    x2={261.5}
                    y2={1942}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#45547C" />
                    <stop offset={1} stopColor="#45547C" stopOpacity={0} />
                </linearGradient>
            </defs>
        </svg>
    );
}

export function Bottle2Icon({ className }) {
    return (
        <svg
            width={314}
            height={1499}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            viewBox="0 0 314 1499"
        >
            <path
                opacity={0.1}
                d="M248.499 361.716c-16.496-16.737-29.52-29.958-29.52-47.442V63.914l2.052-1.95c2.737-2.601 4.283-6.141 4.283-9.826v-25.6c0-3.684-1.546-7.224-4.283-9.825l-1.621-1.541V2.89c0-1.59-1.369-2.89-3.041-2.89H97.631c-1.672 0-3.04 1.3-3.04 2.89v12.282l-1.622 1.541c-2.737 2.601-4.283 6.141-4.283 9.826v25.575c0 3.685 1.546 7.225 4.282 9.826l2.053 1.95v250.36c0 17.484-13.024 30.705-29.52 47.442C37.856 389.772 0 428.183 0 512.953v919.817C0 1499 40.06 1499 53.212 1499h207.576c13.151 0 53.212 0 53.212-66.23V512.953c0-84.77-37.856-123.181-65.501-151.237z"
                fill="#2D4B9D"
            />
        </svg>
    );
}

export function Bottle3Icon({ className }) {
    return (
        <svg
            width={350}
            height={1298}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            viewBox="0 0 350 1298"
        >
            <path
                opacity={0.15}
                d="M276.989 403.011c-18.387-18.648-32.904-33.379-32.904-52.859V71.212l2.288-2.174c3.05-2.898 4.773-6.842 4.773-10.948V29.568c0-4.105-1.723-8.05-4.773-10.947l-1.808-1.717V3.22c0-1.771-1.525-3.22-3.389-3.22H108.824c-1.864 0-3.389 1.449-3.389 3.22v13.684l-1.808 1.717c-3.05 2.898-4.773 6.842-4.773 10.947v28.496c0 4.105 1.723 8.05 4.773 10.947l2.288 2.173v278.942c0 19.479-14.517 34.21-32.904 52.858C42.197 434.269 0 477.066 0 571.513v652.697C0 1298 44.654 1298 59.313 1298h231.375c14.658 0 59.312 0 59.312-73.79V571.513c0-94.447-42.197-137.244-73.011-168.502z"
                fill="url(#prefix__paint0_linear_529_1405)"
            />
            <defs>
                <linearGradient
                    id="prefix__paint0_linear_529_1405"
                    x1={175}
                    y1={201.183}
                    x2={175}
                    y2={1298}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#45547C" />
                    <stop offset={1} stopColor="#45547C" stopOpacity={0} />
                </linearGradient>
            </defs>
        </svg>
    );
}