export function CarIcon({ className }) {
    return (
        <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <g clip-path="url(#clip0_525_1271)">
                <path d="M6.75 19.5C6.75 18.9477 7.19772 18.5 7.75 18.5H11.25V22.25H7.75C7.19772 22.25 6.75 21.8023 6.75 21.25V19.5Z" fill="#3D3D58" />
                <path d="M41.25 19.5C41.25 18.9477 40.8023 18.5 40.25 18.5H36.75V22.25H40.25C40.8023 22.25 41.25 21.8023 41.25 21.25V19.5Z" fill="#3D3D58" />
                <path d="M41.9998 33.5V40.5C41.9998 41.0304 41.789 41.5391 41.414 41.9142C41.0389 42.2893 40.5302 42.5 39.9998 42.5H35.9998C35.4693 42.5 34.9606 42.2893 34.5855 41.9142C34.2105 41.5391 33.9998 41.0304 33.9998 40.5V37.5" fill="#3D3D58" />
                <path d="M5.99951 33.5V40.5C5.99951 41.0304 6.21022 41.5391 6.5853 41.9142C6.96037 42.2893 7.46908 42.5 7.99951 42.5H11.9995C12.5299 42.5 13.0387 42.2893 13.4137 41.9142C13.7888 41.5391 13.9995 41.0304 13.9995 40.5V37.5" fill="#3D3D58" />
                <path d="M6 33.5V27.9909C6 26.3982 6.63232 24.8707 7.758 23.744L10 21.5H38L40.242 23.742C41.3676 24.8676 42 26.3943 42 27.9862V33.5C42 34.5609 41.5786 35.5783 40.8284 36.3284C40.0783 37.0786 39.0609 37.5 38 37.5H10C8.93913 37.5 7.92172 37.0786 7.17157 36.3284C6.42143 35.5783 6 34.5609 6 33.5Z" fill="#FFA681" />
                <rect x="10.5" y="29" width="6" height="2.25" rx="1.125" fill="#FFEDD1" />
                <rect x="31.5" y="29" width="6" height="2.25" rx="1.125" fill="#FFEDD1" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6718 14.2138L9.99976 18.9878V21.4998H37.9998V18.9878L36.5338 14.3398C36.273 13.2461 35.6511 12.2722 34.7687 11.5755C33.8863 10.8787 32.7948 10.4998 31.6704 10.4998H16.5052C15.4029 10.4997 14.3314 10.8638 13.4574 11.5355C12.5833 12.2071 11.9556 13.1486 11.6718 14.2138Z" fill="#A6EAFF" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6718 14.2138L9.99976 18.9878V21.4998H37.9998V18.9878L36.5338 14.3398C36.273 13.2461 35.6511 12.2722 34.7687 11.5755C33.8863 10.8787 32.7948 10.4998 31.6704 10.4998H16.5052C15.4029 10.4997 14.3314 10.8638 13.4574 11.5355C12.5833 12.2071 11.9556 13.1486 11.6718 14.2138ZM35.9998 19.2957L34.6264 14.9414C34.612 14.8959 34.5993 14.85 34.5883 14.8037C34.4318 14.1475 34.0588 13.5632 33.5293 13.1452C32.9999 12.7271 32.345 12.4998 31.6704 12.4998H16.5052C15.8436 12.4997 15.2005 12.7183 14.676 13.1213C14.1514 13.5244 13.7747 14.0895 13.6043 14.7288C13.5912 14.778 13.5762 14.8267 13.5593 14.8748L11.9998 19.3279V20H35.9998V19.2957Z" fill="#3D3D58" />
            </g>
            <defs>
                <clipPath id="clip0_525_1271">
                    <rect width="48" height="48" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>

    );
}


export function DocsIcon({ className }) {
    return (
        <svg
            width={64}
            height={64}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            viewBox="0 0 64 64"
        >
            <path
                d="M7 14C7 8.477 11.477 4 17 4h22l18 18v28c0 5.523-4.477 10-10 10H17c-5.523 0-10-4.477-10-10V14z"
                fill="#fff"
            />
            <path
                d="M21 37.9h4.4M21 45.6h22M21 30.2h4.4"
                stroke="#595A80"
                strokeWidth={3}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M33.925 35.78h8.25M33.1 37.9l3.85-9.9h2.2L43 37.9"
                stroke="#6385FF"
                strokeWidth={3}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                opacity={0.2}
                d="M57 36.5L41.234 20.664h7.72L57 22v14.5z"
                fill="#377EB1"
            />
            <path d="M39 4l18 18H45a6 6 0 01-6-6V4z" fill="#BDDFFF" />
        </svg>
    );
}

export function WarningsIcon({ className }) {
    return (
        <svg
            width={64}
            height={64}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            viewBox="0 0 64 64"
        >
            <g clipPath="url(#prefix__clip0_468_1276)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M32 58c14.36 0 26-11.64 26-26S46.36 6 32 6 6 17.64 6 32c0 5.06 1.445 9.782 3.945 13.776L8.24 55.62a1 1 0 001.144 1.158l10.62-1.705A25.887 25.887 0 0032 58z"
                    fill="#FF6B6B"
                />
                <circle
                    opacity={0.6}
                    cx={32}
                    cy={32}
                    r={19}
                    stroke="#fff"
                    strokeWidth={3}
                />
                <circle cx={32} cy={40} r={3} fill="#fff" />
                <rect x={29} y={21} width={6} height={13} rx={3} fill="#fff" />
            </g>
            <defs>
                <clipPath id="prefix__clip0_468_1276">
                    <path fill="#fff" d="M0 0h64v64H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}


export function AppealsIcon({ className }) {
    return (
        <svg
            width={64}
            height={64}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            viewBox="0 0 64 64"
        >
            <rect x={4} y={6} width={52} height={49} rx={10} fill="#fff" />
            <path
                d="M15.2 20.867H46M15.2 30.666h11.2M15.2 40.467h11.2"
                stroke="#595A80"
                strokeWidth={3}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M35.144 34.644a.4.4 0 01.5-.5l8.252 2.56c.837.26 1.6.72 2.22 1.34L58.96 50.89v7.071L39.045 45.116a5.405 5.405 0 01-1.34-2.22l-2.561-8.252z"
                fill="#FDE9CB"
            />
            <path
                d="M35.143 34.644a.4.4 0 01.501-.5l8.252 2.56c.837.26 1.6.72 2.22 1.34L58.96 50.89v7.071L39.044 45.116a5.405 5.405 0 01-1.34-2.22l-2.56-8.252z"
                fill="#FDE9CB"
            />
            <path
                d="M46.585 38.515L58.96 50.889a5 5 0 01-7.071 7.071L39.514 45.586l7.071-7.071z"
                fill="#4C7EE0"
            />
            <path
                d="M36.077 37.651l-1.233-3.293a.4.4 0 01.514-.515l3.296 1.231-2.577 2.577z"
                fill="#000"
            />
        </svg>
    );
}


export function LicenseIcon({ className }) {
    return (
        <svg
            width={64}
            height={64}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            viewBox="0 0 64 64"
        >
            <path
                d="M7 14C7 8.477 11.477 4 17 4h22l18 18v28c0 5.523-4.477 10-10 10H17c-5.523 0-10-4.477-10-10V14z"
                fill="#fff"
            />
            <path
                opacity={0.2}
                d="M57 36.5L41.234 20.664h7.72L57 22v14.5z"
                fill="#66CD89"
            />
            <path
                d="M38 33.5l-7.5 8-4.5-4.8"
                stroke="#71E298"
                strokeWidth={4}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M39 4l18 18H45a6 6 0 01-6-6V4z" fill="#77DCCA" />
        </svg>
    );
}