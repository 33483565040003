import { RoadmapDocsIcon } from '../../../../assets/icons/services';
import './Roadmap.css';

function Roadmap({ roadmap, service }) {
    return (
        <div className='roadmap'>
            {roadmap.map((item, i) => (
                <div className={`roadmap__card roadmap__card_type_${service.key}`} key={`roadmap__card roadmap__card_type_${service.key}${i}`}>
                    <div className={`roadmap__card-point-and-line ${roadmap.length <= i + 1 ? 'roadmap__card-point-and-line_last' : ''}`}>
                        <div className='roadmap__card-point-box'>
                            {item.type === 'circle' ?
                                <div className={`roadmap__card-point`}>
                                    <p className='roadmap__card-point-number'>0{i + 1}</p>
                                </div>
                                : null}
                            {item.type === 'final' ?
                                <RoadmapDocsIcon
                                    className={'roadmap__card-docs-icon'}
                                />
                                : null}

                        </div>
                        <div className='roadmap__card-line'></div>
                    </div>
                    <div className='roadmap__card-text-box'>
                        <p className='roadmap__card-text' dangerouslySetInnerHTML={{
                            __html: item.text,
                        }}></p>
                    </div>

                </div>
            ))}
        </div>
    );
}

export default Roadmap