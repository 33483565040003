import './ServiceCard.css';

// export const OBTAINING_LICENSES = {
//     title: 'Получение лицензий',
//     price: '65.000₽',
//     period: '1 год',
//     term_moscow: 'от 28 дней',
//     term_moscow_area: 'от 14 дней',
//     short_hint: 'Срок действия можно продлить до&nbsp;5&nbsp;лет',
//     key: 'obtaining-licenses',
//     icon: ObtainingIcon,
// }

function ServiceCard({ service }) {
    return (
        <div className={`service-card service-card_type_${service.key}`}>
            <h3 className='service-card__title'>{service.title}</h3>
            <div className='service-card__info'>
                <div className='service-card__price-box'>
                    <p className='service-card__avarage'>≈</p>
                    <p className='service-card__price'>{service.price}</p>
                    <p className='service-card__period'>{service.period}</p>
                </div>
                <div className='service-card__terms'>
                    <div className='service-card__term'>
                        <p className='service-card__term-name'>Москва</p>
                        <p className='service-card__term-value'>{service.term_moscow}</p>
                    </div>
                    <div className='service-card__term-line'></div>
                    <div className='service-card__term'>
                        <p className='service-card__term-name'>Московская область</p>
                        <p className='service-card__term-value'>{service.term_moscow_area}</p>
                    </div>
                </div>
                {service.short_hint ?
                    <p className='service-card__short-hint' dangerouslySetInnerHTML={{
                        __html: service.short_hint,
                    }}></p> : null}
            </div>
            {service.icon({ className: `service-card__icon service-card__icon_type_${service.key}` })}
        </div>
    );
}

export default ServiceCard