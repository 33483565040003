export function TimeIcon({ className }) {
    return (
        <svg
            width={112}
            height={136}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            viewBox="0 0 112 136"
        >
            <circle cx={56} cy={68} r={55.722} fill="#7794B7" />
            <circle cx={56} cy={68} r={41} fill="#6CADA1" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.668 0c-1.417 0-2.565 1.015-2.565 2.267V6.8c0 1.252 1.148 2.267 2.564 2.267h66.667c1.416 0 2.564-1.015 2.564-2.267V2.267C91.898 1.015 90.75 0 89.334 0H22.668zm0 135.999c-1.417 0-2.565-1.015-2.565-2.266v-4.534c0-1.251 1.148-2.266 2.564-2.266h66.667c1.416 0 2.564 1.015 2.564 2.266v4.534c0 1.251-1.148 2.266-2.564 2.266H22.668z"
                fill="#C65151"
            />
            <path
                d="M14.974 11.333h82.051l-7.692 6.8H22.667l-7.693-6.8z"
                fill="#8F3030"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.975 2.266c-1.417 0-2.565 1.015-2.565 2.267v4.533c0 1.252 1.148 2.267 2.565 2.267h82.05c1.416 0 2.564-1.015 2.564-2.267V4.533c0-1.252-1.147-2.267-2.564-2.267h-82.05z"
                fill="#C65151"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.975 2.266c-1.417 0-2.565 1.015-2.565 2.267h87.18c0-1.252-1.148-2.267-2.565-2.267h-82.05z"
                fill="#E68181"
            />
            <path
                opacity={0.7}
                fill="#6F1F1F"
                d="M38.051 11.333h35.897v6.8H38.051z"
            />
            <path opacity={0.4} fill="#6F1F1F" d="M32.922 4.532h46.154v6.8H32.922z" />
            <path
                d="M14.974 124.667h82.051l-7.692-6.8H22.667l-7.693 6.8z"
                fill="#8F3030"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.975 133.733c-1.417 0-2.565-1.015-2.565-2.266v-4.534c0-1.252 1.148-2.266 2.565-2.266h82.05c1.416 0 2.564 1.014 2.564 2.266v4.534c0 1.251-1.147 2.266-2.564 2.266h-82.05z"
                fill="#C65151"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.975 133.732c-1.417 0-2.565-1.015-2.565-2.267h87.18c0 1.252-1.148 2.267-2.565 2.267h-82.05z"
                fill="#E68181"
            />
            <path
                opacity={0.7}
                fill="#6F1F1F"
                d="M38.051 124.667h35.897v-6.8H38.052z"
            />
            <path
                opacity={0.4}
                fill="#6F1F1F"
                d="M32.922 131.465h46.154v-6.8H32.922z"
            />
            <path
                opacity={0.7}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.667 32.575c0 7.095 3.236 13.892 8.975 18.852l17.093 14.775c.547.473.855 1.12.855 1.797 0 .676-.308 1.324-.855 1.797L31.642 84.571c-5.74 4.96-8.975 11.757-8.975 18.852v14.442h66.667v-14.442c0-7.095-3.235-13.892-8.975-18.852L63.266 69.796c-.547-.473-.855-1.12-.855-1.797 0-.676.308-1.324.855-1.797L80.36 51.427c5.74-4.96 8.975-11.757 8.975-18.852V18.133H22.667v14.442z"
                fill="#A9E0FF"
            />
            <path
                opacity={0.2}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M68.222 58.176c9.125-7.017 18.547-14.264 18.547-25.601V18.133H25.231v14.442c0 11.337 9.423 18.584 18.548 25.6 1.264.973 2.523 1.941 3.75 2.915 2.334 1.852 4.23 4.137 4.23 6.909 0 2.772-1.896 5.057-4.23 6.909-1.227.974-2.486 1.942-3.75 2.914-9.125 7.017-18.548 14.264-18.548 25.601v14.442H86.77v-14.442c0-11.337-9.422-18.584-18.547-25.6-1.264-.973-2.523-1.941-3.75-2.915-2.334-1.852-4.23-4.137-4.23-6.909 0-2.772 1.896-5.057 4.23-6.909a311.575 311.575 0 013.75-2.914z"
                fill="#345818"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M57.539 88.286a2.837 2.837 0 00-3.077 0l-13.533 8.972c-5.25 3.481-9.404 8.09-12.07 13.391l-3.628 7.217H86.77l-3.628-7.217c-2.665-5.301-6.82-9.91-12.07-13.391L57.54 88.286zM28.722 40.998c-1.046-1.168-.402-2.902 1.225-3.294l1.198-.289a42.342 42.342 0 0118.994-.179 42.335 42.335 0 0011.886.901l7.157-.468a52.173 52.173 0 018.24.112l3.43.32c2.01.186 2.997 2.26 1.743 3.66l-7.477 8.35a31.368 31.368 0 01-3.99 3.733l-11.72 9.21c-1.944 1.527-4.872 1.527-6.815 0l-11.721-9.21a31.368 31.368 0 01-3.99-3.733l-8.16-9.112z"
                fill="#E2C69C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.917 43.449c4.758 2.458 9.343 4.149 13.545 4.149 4.021 0 8.393-.93 12.565-1.816 3.81-.81 7.452-1.584 10.511-1.584 4.827 0 11.832-.642 15.548-4.345-.232-.888-1.058-1.643-2.233-1.753l-3.43-.319a52.173 52.173 0 00-8.241-.112l-7.157.468a42.335 42.335 0 01-11.886-.9 42.343 42.343 0 00-18.994.178l-1.198.289c-1.627.392-2.271 2.126-1.225 3.294l2.195 2.45z"
                fill="#EED5B1"
            />
        </svg>
    );
}

export function NoteIcon({ className }) {
    return (
        <svg
            width={130}
            height={142}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            viewBox="0 0 130 142"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.044 24.044c0-5.403 4.38-9.783 9.782-9.783h74.348c5.403 0 9.783 4.38 9.783 9.783v101.739c0 5.402-4.38 9.782-9.783 9.782H27.826c-5.403 0-9.782-4.38-9.782-9.782V24.043z"
                fill="#5F71B1"
            />
            <path
                d="M111.957 27.987l-6.94-6.634-81.104 8.56 1.1 98.499 8.683 7.153h73.261a5 5 0 005-5V27.987z"
                fill="#4A598D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.826 20.13a3.913 3.913 0 00-3.913 3.914v101.739a3.913 3.913 0 003.913 3.913h74.348a3.913 3.913 0 003.913-3.913V24.043a3.913 3.913 0 00-3.913-3.912H27.826z"
                fill="#98BEEB"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.826 20.13a3.913 3.913 0 00-3.913 3.914v99.782a3.913 3.913 0 003.913 3.913h74.348a3.913 3.913 0 003.913-3.913V24.044a3.913 3.913 0 00-3.913-3.913H27.826z"
                fill="#E0F2FF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.826 20.13a3.913 3.913 0 00-3.913 3.914v78.596c0 1.038.412 2.033 1.146 2.767l19.23 19.23a3.916 3.916 0 002.767 1.146h55.118a3.913 3.913 0 003.913-3.913V24.043a3.913 3.913 0 00-3.913-3.912H27.826z"
                fill="#FDFEFF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39.565 14.26c-1.08 0-1.956.877-1.956 1.957v3.914a5.87 5.87 0 005.87 5.87h43.043a5.87 5.87 0 005.87-5.87v-3.914c0-1.08-.877-1.956-1.957-1.956h-50.87z"
                fill="#8F9AC4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39.565 12.304c-1.08 0-1.956.876-1.956 1.957v3.913a5.87 5.87 0 005.87 5.87h43.043a5.87 5.87 0 005.87-5.87V14.26c0-1.08-.877-1.957-1.957-1.957h-50.87z"
                fill="#B4CCEF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39.565 12.304c-1.08 0-1.956.876-1.956 1.957v1.956H92.39v-1.956c0-1.08-.876-1.957-1.956-1.957h-50.87z"
                fill="#EAF2FF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M51.304 6.435a3.913 3.913 0 00-3.913 3.913v1.956H82.61v-1.956a3.913 3.913 0 00-3.913-3.913H51.304z"
                fill="#6A81A4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M47.391 10.348v1.956H82.61v-1.956H47.39z"
                fill="#566A87"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M59.13 8.391a1.957 1.957 0 000 3.913h11.74a1.957 1.957 0 000-3.913H59.13z"
                fill="#2F3D51"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M41.522 104.261a3.913 3.913 0 013.913 3.913v17.609l-21.522-21.522h17.609z"
                fill="#CEEAFF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M41.522 44.587a2.935 2.935 0 012.934-2.935h41.087a2.935 2.935 0 010 5.87H44.456a2.935 2.935 0 01-2.934-2.935zm0 13.696a2.935 2.935 0 012.934-2.935h41.087a2.935 2.935 0 110 5.87H44.456a2.935 2.935 0 01-2.934-2.935zm2.934 10.76a2.935 2.935 0 000 5.87h13.696a2.935 2.935 0 000-5.87H44.456z"
                fill="#7482A5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M81.452 81.263l-2.715 2.715 38.012 38.012 2.715-2.715-38.012-38.012z"
                fill="#FFE08F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M73.307 89.407l2.715-2.715 38.013 38.013-2.715 2.715-38.013-38.013z"
                fill="#F8CF68"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M76.02 86.694l2.716-2.716 38.013 38.013-2.716 2.715-38.012-38.013z"
                fill="#CAA035"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M81.45 81.262l-16.29-8.146 8.145 16.291 8.146-8.145z"
                fill="#FFF6D5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M80.093 82.62l-14.934-9.503 9.503 14.933 5.43-5.43z"
                fill="#F4E5AE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M65.159 73.116l5.43 2.715-2.715 2.715-2.715-5.43z"
                fill="#161D31"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M115.392 115.203l-8.145 8.146 9.503 9.503 8.145-8.146-9.503-9.503z"
                fill="#8693B4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M115.392 115.202l-2.716 2.715 9.503 9.503 2.716-2.715-9.503-9.503z"
                fill="#AEBFEB"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M112.676 117.916l-2.715 2.715 9.503 9.503 2.715-2.715-9.503-9.503z"
                fill="#617199"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M124.893 130.134a3.84 3.84 0 000-5.43l-8.146 8.145a3.84 3.84 0 005.431 0l2.715-2.715z"
                fill="#FFC4A3"
            />
        </svg>
    );
}


export function GarantIcon({ className }) {
    return (
        <svg
            width={130}
            height={144}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            viewBox="0 0 130 144"
        >
            <path
                d="M130 81.605c0 30.713-41.808 53.217-58.364 60.924A15.655 15.655 0 0165 144c-2.3 0-4.57-.503-6.636-1.471C41.808 134.822 0 112.318 0 81.605V32.541c0-2.986.95-5.9 2.723-8.354 1.774-2.454 4.286-4.33 7.201-5.38L59.63.935a15.907 15.907 0 0110.74 0l49.706 17.875c2.915 1.048 5.427 2.925 7.201 5.379A14.254 14.254 0 01130 32.542v49.063z"
                fill="#7BDDC6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M65 0c-1.82 0-3.639.311-5.37.934L9.924 18.809c-2.915 1.048-5.427 2.925-7.2 5.379A14.252 14.252 0 000 32.542v49.063c0 30.713 41.807 53.217 58.364 60.924A15.655 15.655 0 0065 144V0z"
                fill="#67C7B0"
            />
            <mask
                id="prefix__a"
                style={{
                    maskType: "alpha",
                }}
                maskUnits="userSpaceOnUse"
                x={0}
                y={0}
                width={130}
                height={144}
            >
                <path
                    d="M130 81.605c0 30.713-41.808 53.217-58.364 60.924A15.655 15.655 0 0165 144c-2.3 0-4.57-.503-6.636-1.471C41.808 134.822 0 112.318 0 81.605V32.541c0-2.986.95-5.9 2.723-8.354 1.774-2.454 4.286-4.33 7.201-5.38L59.63.935a15.907 15.907 0 0110.74 0l49.706 17.875c2.915 1.048 5.427 2.925 7.201 5.379A14.254 14.254 0 01130 32.542v49.063z"
                    fill="#7BDDC6"
                />
            </mask>
            <g mask="url(#prefix__a)">
                <path fill="#CBFCFF" d="M47.272 82.001h35.455v60H47.272z" />
                <path fill="#AEE9E5" d="M47.272 82.001h17.727v60H47.272z" />
                <path fill="#ABF1ED" d="M21.665 96.001h21.667v46H21.665z" />
                <path d="M43.332 96.001l3.94 4v42h-3.94v-46z" fill="#57A8AC" />
                <path d="M86.666 96.001l-3.939 4v42h3.94v-46z" fill="#71B9BD" />
                <path fill="#C8F2FF" d="M86.666 96.001h21.667v46H86.666z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M53.181 92h23.637v4H53.18v-4zm0 8h23.637v4H53.18v-4zm23.637 8H53.18v4h23.637v-4zm-23.637 8h23.637v4H53.18v-4zm23.637 8H53.18v4h23.637v-4z"
                    fill="#85C2D6"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M94.544 100.001h-3.94v6h3.94v-6zm0 10h-3.94v6h3.94v-6zm-3.94 10h3.94v6h-3.94v-6zm13.789-20h-3.94v6h3.94v-6zm-3.94 10h3.94v6h-3.94v-6zm3.94 10h-3.94v6h3.94v-6z"
                    fill="#9BD0E1"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M29.545 100.001h-3.94v6h3.94v-6zm0 10h-3.94v6h3.94v-6zm-3.94 10h3.94v6h-3.94v-6zm13.789-20h-3.94v6h3.94v-6zm-3.94 10h3.94v6h-3.94v-6zm3.94 10h-3.94v6h3.94v-6z"
                    fill="#75BFC2"
                />
                <path fill="#D9FFF6" d="M21.665 94.002h21.667v2H21.665z" />
                <path d="M43.332 94.002l3.94 4v2l-3.94-4v-2z" fill="#48989C" />
                <path fill="#D9FFF6" d="M108.332 94.002H86.665v2h21.667z" />
                <path d="M86.666 94.002l-3.94 4v2l3.94-4v-2z" fill="#5EA7AB" />
            </g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M69.16 137.081l.013-.006c8.091-3.767 22.156-11.067 34.106-21.02 12.172-10.137 20.812-21.898 20.812-34.45V32.541a8.192 8.192 0 00-1.579-4.805c-1.048-1.451-2.574-2.612-4.409-3.272L68.397 6.59a10.083 10.083 0 00-6.794 0L59.63.934a15.907 15.907 0 0110.74 0l49.706 17.875c2.915 1.048 5.427 2.925 7.201 5.379A14.254 14.254 0 01130 32.542v49.063c0 30.713-41.808 53.217-58.364 60.924A15.655 15.655 0 0165 144c-2.3 0-4.57-.503-6.636-1.471C41.808 134.822 0 112.318 0 81.605V32.541c0-2.986.95-5.9 2.723-8.354 1.774-2.454 4.286-4.33 7.201-5.38L59.63.935l1.973 5.656-49.706 17.875c-1.835.66-3.36 1.82-4.41 3.272a8.19 8.19 0 00-1.578 4.805v49.063c0 12.552 8.64 24.313 20.812 34.45 11.95 9.953 26.015 17.253 34.106 21.02l.012.006A9.83 9.83 0 0065 138a9.83 9.83 0 004.16-.919z"
                fill="#ADFDEA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M94.546 40.744L57.493 75.998 35.456 55.03v-5.582l10.29-5.471 11.747 11.176L84.255 29.69l10.291 5.528v5.526z"
                fill="#479F8A"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M94.546 35.053L57.493 70.307 35.456 49.339l10.29-11.054 11.747 11.177 26.762-25.463 10.291 11.054z"
                fill="#fff"
            />
        </svg>
    );
}

export function ReturnIcon({ className }) {
    return (
        <svg
            width={130}
            height={130}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            viewBox="0 0 130 130"
        >
            <path d="M53.26 18.044L7.284 31.739H53.26V18.044z" fill="#5F4A2B" />
            <rect
                x={81.608}
                y={0.435}
                width={62.609}
                height={82.174}
                rx={2}
                transform="rotate(63.2 81.608 .435)"
                fill="#F2C6AE"
            />
            <path
                d="M57.176 17.16a3.913 3.913 0 015.257 1.728l21.172 41.913a3.913 3.913 0 01-1.729 5.257L38.217 88.111a3.913 3.913 0 01-5.257-1.728L11.789 44.47a3.913 3.913 0 011.728-5.257l43.66-22.053z"
                fill="#F2C6AE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M81.858 61.683L60.687 19.77a1.956 1.956 0 00-2.628-.864l-43.66 22.053a1.956 1.956 0 00-.864 2.629l21.171 41.913a1.957 1.957 0 002.629.864l43.66-22.053a1.956 1.956 0 00.863-2.629zM62.433 18.888a3.913 3.913 0 00-5.257-1.728L13.517 39.213a3.913 3.913 0 00-1.728 5.257L32.96 86.383a3.913 3.913 0 005.257 1.728l43.66-22.053a3.913 3.913 0 001.728-5.257L62.433 18.888z"
                fill="#E19D93"
            />
            <path
                d="M79.822 1.337a2 2 0 012.687.883l26.425 52.313a2 2 0 01-.883 2.687l-17.425 8.802-28.229-55.884 17.425-8.801z"
                fill="#D7E7FF"
            />
            <path
                fill="#1472FF"
                d="M101.933 49.351l1.764 3.493-12.225 6.175-1.764-3.493zM97.54 44.994l1.764 3.493-8.732 4.41-1.764-3.492z"
            />
            <rect
                x={101.331}
                y={10.217}
                width={62.609}
                height={82.174}
                rx={2}
                transform="rotate(75 101.331 10.217)"
                fill="#B1D0FF"
            />
            <path
                d="M73.996 21.593a3.913 3.913 0 014.793 2.767l12.153 45.356a3.913 3.913 0 01-2.767 4.793L40.93 87.169a3.913 3.913 0 01-4.793-2.767L23.983 39.044a3.913 3.913 0 012.767-4.793l47.246-12.66z"
                fill="#B1D0FF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M89.052 70.223L76.9 24.866a1.956 1.956 0 00-2.396-1.383l-47.247 12.66a1.957 1.957 0 00-1.383 2.396l12.153 45.356a1.956 1.956 0 002.396 1.384l47.247-12.66a1.957 1.957 0 001.383-2.396zM78.79 24.36a3.913 3.913 0 00-4.793-2.767L26.75 34.253a3.913 3.913 0 00-2.767 4.792l12.153 45.356a3.913 3.913 0 004.793 2.767l47.246-12.66a3.913 3.913 0 002.767-4.792L78.789 24.36z"
                fill="#939BE1"
            />
            <path
                d="M99.4 10.735a2 2 0 012.449 1.414l15.169 56.612a2 2 0 01-1.414 2.45l-18.857 5.052-16.204-60.475 18.856-5.053z"
                fill="#D7E7FF"
            />
            <path
                fill="#1472FF"
                d="M111.224 62.256l1.013 3.78-13.23 3.545-1.012-3.78zM107.815 57.093l1.013 3.78-9.45 2.532-1.013-3.78z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.26 31.74a7.826 7.826 0 00-7.825 7.825v82.174a7.826 7.826 0 007.826 7.826H110c8.644 0 15.652-7.007 15.652-15.652V47.391c0-8.644-7.008-15.652-15.652-15.652H8.26z"
                fill="#FFCF87"
            />
            <path
                d="M82.233 76.054l-13.974 7.048-3.737-3.737a.343.343 0 01.086-.548l4.109-2.074a.342.342 0 00-.047-.63l-16.157-5.322a.34.34 0 00-.43.43l5.325 16.158c.094.29.493.317.63.047l2.074-4.109a.343.343 0 01.548-.086l3.737 3.737-7.048 13.974a.684.684 0 01-1.26-.094L43.497 62.636a.34.34 0 01.43-.43L82.14 74.794c.579.192.638.987.094 1.26z"
                fill="#C1985C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M110 35.652H8.26a3.913 3.913 0 00-3.912 3.913v82.174a3.913 3.913 0 003.913 3.913H110c6.483 0 11.739-5.256 11.739-11.739V47.391c0-6.483-5.256-11.739-11.739-11.739zM8.26 31.74a7.826 7.826 0 00-7.825 7.826v82.174a7.826 7.826 0 007.826 7.826H110c8.644 0 15.652-7.007 15.652-15.652V47.391c0-8.644-7.008-15.652-15.652-15.652H8.26z"
                fill="#C29E67"
            />
            <path
                d="M125.652 114.341l-23.876-24.456 23.876-.428v24.884z"
                fill="#78603B"
                fillOpacity={0.78}
            />
            <path
                d="M98.26 81.753c0-7.564 6.133-13.696 13.697-13.696h15.608a2 2 0 012 2v23.391a2 2 0 01-2 2h-15.608c-7.564 0-13.696-6.131-13.696-13.695z"
                fill="#6C5127"
            />
            <path
                d="M98.26 80.652c0-7.564 6.133-13.695 13.697-13.695h15.608a2 2 0 012 2v23.39a2 2 0 01-2 2h-15.608c-7.564 0-13.696-6.13-13.696-13.695z"
                fill="#DFAE65"
            />
            <path
                d="M125.652 66.957h1.913a2 2 0 012 2v23.39a2 2 0 01-2 2h-1.913v-27.39z"
                fill="#F1BF75"
            />
            <circle cx={110} cy={80.652} r={5.87} fill="#7E8497" />
            <path
                opacity={0.3}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.26 31.74a7.826 7.826 0 00-7.825 7.825v82.174a7.826 7.826 0 007.826 7.826h1.956a3.913 3.913 0 003.913-3.913v-90a3.913 3.913 0 00-3.913-3.913H8.261z"
                fill="#AB8854"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M.435 39.565a7.823 7.823 0 013.913-6.779v95.732a7.822 7.822 0 01-3.913-6.779V39.565z"
                fill="#99753F"
            />
        </svg>
    );
}