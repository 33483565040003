
import { Route, Routes } from 'react-router-dom';
import Header from '../Header/Header';
import './App.css';
import Main from '../Main/Main';
import ServicesPage from '../ServicesPage/ServicesPage';

// import Footer from '../Footer/Footer';

function App() {
  return (
    <div className="app">
      <Header />
      <Routes>
        <Route index path='/' element={<Main />}></Route>
        <Route path='/services/*' element={<ServicesPage />}></Route>

      </Routes>
      {/* <Footer /> */}
    </div>
  );
}

export default App;
