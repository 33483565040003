import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Mousewheel } from "swiper";

import { LogoClickIcon, ProcessArrowIcon, ProcessIcon, ProcessMiniArrowIcon } from '../../../assets/icons/icons';
import { AppealsIcon, CarIcon, DocsIcon, LicenseIcon, WarningsIcon } from '../../../assets/icons/process';
import './Process.css';

import "swiper/css";
import "swiper/css/pagination";
import { useState } from 'react';


const PROCESS_CARDS = [
    {
        title: 'Выезд',
        text: 'Выезжаем на&nbsp;проверку вашего объекта на&nbsp;предмет ограничений и&nbsp;составляем заключение',
        icon: CarIcon,
    },
    {
        title: 'Договор',
        text: 'Заключаем договор с&nbsp;указанием этапов, сроков и&nbsp;гарантий на&nbsp;получение лицензии',
        icon: DocsIcon,
    },
    {
        title: 'Нарушения',
        text: 'Исправляем выявленные нарушения на&nbsp;торговом объекте',
        icon: WarningsIcon,
    },
    {
        title: 'Заявление',
        text: 'Подаём заявление на&nbsp;получение лицензии в&nbsp;лицензирующий орган по&nbsp;вашей области',
        icon: AppealsIcon,
    },
    {
        title: 'Лицензия',
        text: 'Проходим все необходимые проверки, гос.экспертизы и&nbsp;получаем лицензию',
        icon: LicenseIcon,
    },
]


function Process() {

    const [my_swiper, set_my_swiper] = useState({});

    return (
        <div className='process'>
            <div className='process__title-box'>
                <h4 className='process__title'>Процесс получения <br />лицензии</h4>
                <div className='process__title-icon-and-arrow'>
                    <ProcessIcon
                        className={'process__title-icon'}
                    />
                    <div className='process__title-arrow'>
                        <ProcessArrowIcon
                            className={'process__title-arrow-icon process__title-arrow-icon_pc'}
                        />
                        <ProcessMiniArrowIcon
                            className={'process__title-arrow-icon process__title-arrow-icon_mobile'}
                        />
                    </div>
                </div>
            </div>
            <div className='process__cards process__cards_pc'>
                {PROCESS_CARDS.map((item, i) => (
                    <div className={`process__card process__card_type_${i + 1}`}>
                        <div className='process__card-heading'>
                            <p className='process__card-number'>0{i + 1}</p>
                            {item.icon({ className: 'process__card-icon' })}
                        </div>
                        <h5 className='process__card-title'>{item.title}</h5>
                        <p
                            className='process__card-text'
                            dangerouslySetInnerHTML={{
                                __html: item.text,
                            }}></p>
                    </div>
                ))}
                <div className='process__action-card'>
                    <Link className='process__action-card-btn' to={'/services/obtaining-licenses'}>
                        Получить лицензию
                    </Link>
                    <LogoClickIcon
                        className={'process__action-card-icon'}
                        fillClassName={'process__action-card-icon-fill'}
                    />
                </div>
            </div>

            <Swiper
                slidesPerView={"auto"}
                spaceBetween={0}
                pagination={{
                    clickable: true,
                    dynamicBullets: true,
                    dynamicMainBullets: 1,
                }}
                mousewheel={{
                    forceToAxis: true,
                }}

                modules={[Mousewheel, Pagination]}
                preventInteractionOnTransition={true}
                className="process__cards process__cards_mobile"
                onInit={(ev) => {
                    set_my_swiper(ev)
                }}

            >
                {PROCESS_CARDS.map((item, i) => (
                    <SwiperSlide className={`process__card process__card_type_${i + 1}`} key={`process__card-slider__card${i}`}>
                        <div className='process__card-heading'>
                            <p className='process__card-number'>0{i + 1}</p>
                            {item.icon({ className: 'process__card-icon' })}
                        </div>
                        <h5 className='process__card-title'>{item.title}</h5>
                        <p
                            className='process__card-text'
                            dangerouslySetInnerHTML={{
                                __html: item.text,
                            }}></p>
                    </SwiperSlide>
                ))}

                <SwiperSlide className='process__action-card'>
                    <Link className='process__action-card-btn' to={'/services/obtaining-licenses'}>
                        Получить лицензию
                    </Link>
                    <LogoClickIcon
                        className={'process__action-card-icon'}
                        fillClassName={'process__action-card-icon-fill'}
                    />
                </SwiperSlide>


            </Swiper>
        </div>
    );
}

export default Process