import { Helmet } from 'react-helmet';
import Roadmap from './Roadmap/Roadmap';
import ServiceCard from './ServiceCard/ServiceCard';
import './ServiceTemplate.css';

function ServiceTemplate({ service }) {
    return (
        <div className='service-template'>
            <Helmet>
                <title>Alclic – {service.title}</title>
            </Helmet>
            <ServiceCard service={service} />
            {service.roadmap ?
                <Roadmap roadmap={service.roadmap} service={service} />
                : null}
        </div>
    );
}

export default ServiceTemplate