import { ADVANTAGES_CARDS } from '../../../assets/utils/constants';
import './Advantages.css';



function Advantages() {
    return (
        <div className='advantages'>
            {ADVANTAGES_CARDS.map((item, i) => (
                <div className='advantages__card'>
                    <div className='advantages__card-icon-box'>
                        {item.icon({ className: `advantages__card-icon-${i + 1}` })}
                    </div>
                    <p className='advantages__card-text'>{item.text}</p>
                </div>
            ))}
        </div>
    );
}

export default Advantages