import { Link } from 'react-router-dom';
import './Header.css';
import { LogoIcon } from '../../assets/icons/icons';
import { MAIN_CONTACT_PHONE } from '../../assets/utils/constants';

function Header() {
  return (
    <header className='header'>
      <Link className='header__logo-box' to={'/'}>
        <LogoIcon
          className={'header__logo'}
          fillClassName={'header__logo-fill'}
        />
        <p className='header__logo-text'>ALCLIC.RU</p>
      </Link>
      {/* <div className='header__links'>
        <Link className='header__link header__link_servises' to={'/'}>
          Услуги
        </Link>
        <Link className='header__link' to={'/'}>
          Этапы работы
        </Link>
        <Link className='header__link' to={'/'}>
          О компании
        </Link>
        <Link className='header__link' to={'/'}>
          Контакты
        </Link>
      </div> */}
      <a className='header__phone' href={`tel:${MAIN_CONTACT_PHONE.number}`}>
        {MAIN_CONTACT_PHONE.display_value}
      </a>
    </header>
  );
}

export default Header