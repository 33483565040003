import { DangerIcon } from '../../../assets/icons/icons';
import './Danger.css';

const DANGER_CARDS = [
    {
        title: `Деятельность
без лицензии`,
        text: 'Влечёт наложение штрафа до&nbsp;500 базовых величин с&nbsp;конфискацией дохода, полученной в&nbsp;результате деятельности, или без конфискации,или лишением права заниматься данной деятельностью (ст.&nbsp;12.7&nbsp;КоАП). При чем под доходом понимается вся выручка, полученная в&nbsp;результате ведения данной деятельности (ст.&nbsp;12.39&nbsp;КоАП).',
    },
    {
        title: `Затраты
времени`,
        text: 'Если вы&nbsp;подаёте документы самостоятельно, внимательно изучите перечень, т.к. нередко лицензирующие органы принимают документы не&nbsp;с&nbsp;первого раза, в&nbsp;связи с&nbsp;чем необходимо будет осуществлять подачу несколько раз и&nbsp;процедура подачи документов может занять даже несколько месяцев.',
    },
    {
        title: `Соответствие
требованиям`,
        text: 'Для получения лицензии необходимо собрать определённый перечень документов, а&nbsp;также соблюсти требования, закреплённые в&nbsp;законодательстве для лицензиатов. А&nbsp;также, получить лицензию повторно будет невозможно.',
    },
]
function Danger() {
    return (
        <div className='danger'>
            <div className='danger__title-box'>
                <h4 className='danger__title'>Опасность работы <br />без лицензии</h4>
                <DangerIcon
                    className={'danger__title-icon'}
                />
            </div>
            <div className='danger__cards'>
                {DANGER_CARDS.map((item, i) => (
                    <div className='danger__card'>
                        <div className='danger__card-heading'>
                            <h5 className='danger__card-title'>{item.title}</h5>
                            <p className='danger__card-number'>0{i + 1}</p>
                        </div>
                        <p
                            className='danger__card-text'
                            dangerouslySetInnerHTML={{
                                __html: item.text,
                            }}></p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Danger