import { SERVICES_LINKS } from "./constants";

export function getServiceName(service_key) {
    return SERVICES_LINKS.find((item) => item.path === service_key).title
    // export const SERVICES_LINKS = [
    //     {
    //         title: 'Получение',
    //         path: 'obtaining_licenses',
    //     },
    //     {
    //         title: 'Продление',
    //         path: 'license_renewal',
    //     },
    //     {
    //         title: 'Переоформление',
    //         path: 'license_reissue',
    //     },
    //     {
    //         title: 'Договор аренды',
    //         path: 'lease_agreement',
    //     },
    // ]
}