import './Footer.css';

function Footer() {
    return (
        <footer className='footer'>
            <div className='footer__content'>
                <p className='footer__copyright'>Ⓒ All Right Reserved. ALCLIC.RU&nbsp;&mdash; 2023</p>
            </div>
        </footer>
    );
}

export default Footer