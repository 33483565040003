import { SuccessPopupIcon } from '../../../assets/icons/icons';
import './SuccessPopup.css';
import React from "react";







function SuccessPopup({
  handleClose,
  isOpen,
}) {

  return (
    <div className={`success-popup ${isOpen ? 'success-popup_active' : ''}`}>
      <div className={`success-popup__container ${isOpen ? 'success-popup__container_active' : ''}`}>
        <SuccessPopupIcon
          className={'success-popup__icon'}
        />
        <h6 className='success-popup__title'>Ваша заявка <br />принята!</h6>
        <p className='success-popup__text'>Наш специалист свяжется <br />с&nbsp;вами в&nbsp;ближайшее время</p>
        <button className='success-popup__btn' type='button' onClick={handleClose}>
          OK
        </button>
      </div>
      <div className={`success-popup__background ${isOpen ? 'success-popup__background_active' : ''}`} onClick={handleClose}>

      </div>
    </div>
  );
}

export default SuccessPopup;
