import { GarantIcon, NoteIcon, ReturnIcon, TimeIcon } from "../icons/advantages"
import { LeaseIcon, ObtainingIcon, ReissueIcon, RenewalIcon } from "../icons/services"


export const MAIN_URL = "https://app.alclic.ru"


export const CONTACT_PHONES = [
    {
        display_value: '+7 495 678-15-63',
        number: '74956781563'
    },
]

export const MAIN_CONTACT_PHONE = CONTACT_PHONES[0]

export const ADVANTAGES_CARDS = [
    {
        text: `Опыт
более 8 лет`,
        icon: TimeIcon,
    },
    {
        text: `Работаем
на результат`,
        icon: NoteIcon,
    },
    {
        text: `Помогли 100+
компаниям`,
        icon: GarantIcon,
    },
    {
        text: `100% гарантия
возврата`,
        icon: ReturnIcon,
    },
]

export const MAIN_ADDRESS = {
    display_value: `Москва, Кутузовский проспект,
36, строение 41`,
    link: 'https://yandex.com/maps/-/CCUcJOq~gA'
}

export const MAIN_WORKTIME = 'Понедельник – Пятница: 09:00 – 18:00'


export const SERVICES_LINKS = [
    {
        long_title: 'Получение лицензий',
        title: 'Получение',
        path: 'obtaining-licenses',
        text: 'Для магазинов и&nbsp;предприятий общественного питания в&nbsp;Москве и&nbsp;Московской области.',
    },
    {
        long_title: 'Продление лицензий',
        title: 'Продление',
        path: 'license-renewal',
        text: 'Срочная помощь в&nbsp;продлении лицензии на&nbsp;оптовую и&nbsp;розничную реализацию алкоголя.',
    },
    {
        long_title: 'Переоформление лицензий',
        title: 'Переоформление',
        path: 'license-reissue',
        text: 'Внесение изменений и&nbsp;переоформлении лицензий на&nbsp;алкоголь&nbsp;&mdash; в&nbsp;срок.',
    },
    {
        long_title: 'Договор аренды',
        title: 'Договор аренды',
        path: 'lease-agreement',
        text: 'Поможем зарегистрировать договор аренды помещения, реализующего алкогольную продукцию.',
    },
]

export const OBTAINING_LICENSES = {
    title: 'Получение лицензий',
    price: '60.000₽',
    period: '1 год',
    term_moscow: 'от 28 дней',
    term_moscow_area: 'от 14 дней',
    short_hint: 'Срок действия можно продлить до&nbsp;5&nbsp;лет',
    key: 'obtaining-licenses',
    icon: ObtainingIcon,
    roadmap: [
        {
            type: 'circle',
            text: 'Подготовка пакета документов, исправление недостоверных сведений и&nbsp;получение отсутствующих документов',
        },
        {
            type: 'circle',
            text: 'Подготовка объекта к&nbsp;проверке',
        },
        {
            type: 'circle',
            text: 'Создание всех обстоятельств для выдачи лицензии',
        },
        {
            type: 'circle',
            text: 'Подача в&nbsp;Департамент торговли Москвы&nbsp;/ Министерство сельского хозяйства Московской Области',
        },
        {
            type: 'circle',
            text: 'Прохождение выездной проверки (ваше участие не&nbsp;требуется)',
        },
        {
            type: 'final',
            text: 'Получение решения о&nbsp;выдаче лицензии',
        },
    ],
}


export const LICENSE_RENEWAL = {
    title: 'Продление лицензий',
    price: '60.000₽',
    period: '1 год',
    term_moscow: 'от 28 дней',
    term_moscow_area: 'от 14 дней',
    short_hint: 'Подача на&nbsp;продление возможна за&nbsp;3&nbsp;месяца до&nbsp;её&nbsp;окончания',
    key: 'license-renewal',
    icon: RenewalIcon,
    roadmap: [
        {
            type: 'circle',
            text: 'Подготовка пакета документов, исправление недостоверных сведений и&nbsp;получение отсутствующих документов',
        },
        {
            type: 'circle',
            text: 'Подготовка объекта к&nbsp;проверке',
        },
        {
            type: 'circle',
            text: 'Создание всех обстоятельств для выдачи лицензии',
        },
        {
            type: 'circle',
            text: 'Подача в&nbsp;Департамент торговли Москвы&nbsp;/ Министерство сельского хозяйства Московской Области',
        },
        {
            type: 'circle',
            text: 'Прохождение выездной проверки (ваше участие не&nbsp;требуется)',
        },
        {
            type: 'final',
            text: 'Получение решения о&nbsp;выдаче лицензии',
        },
    ],
}

export const LICENSE_REISSUE = {
    title: 'Переоформление лицензий',
    price: '60.000₽',
    period: '1 год',
    term_moscow: 'от 28 дней',
    term_moscow_area: 'от 14 дней',
    short_hint: '',
    key: 'license-reissue',
    icon: ReissueIcon,
    roadmap: [
        {
            type: 'circle',
            text: 'Подготовка пакета документов, исправление недостоверных сведений и&nbsp;получение отсутствующих документов',
        },
        {
            type: 'circle',
            text: 'Подготовка объекта к&nbsp;проверке',
        },
        {
            type: 'circle',
            text: 'Создание всех обстоятельств для выдачи лицензии',
        },
        {
            type: 'circle',
            text: 'Подача в&nbsp;Департамент торговли Москвы&nbsp;/ Министерство сельского хозяйства Московской Области',
        },
        {
            type: 'circle',
            text: 'Прохождение выездной проверки (ваше участие не&nbsp;требуется)',
        },
        {
            type: 'final',
            text: 'Получение решения о&nbsp;выдаче лицензии',
        },
    ],
}

export const LEASE_AGREEMENT = {
    title: 'Регистрация договора аренды',
    price: '10.000₽',
    period: '1 год',
    term_moscow: 'от 5 дней',
    term_moscow_area: 'от 5 дней',
    short_hint: '',
    key: 'lease-agreement',
    icon: LeaseIcon,
    roadmap: [
        {
            type: 'circle',
            text: 'В&nbsp;связи с&nbsp;внесением изменений в&nbsp;упомянутый выше Федеральный закон от&nbsp;22.11.1995&nbsp;N 171-ФЗ &laquo;О&nbsp;государственном регулировании производства и&nbsp;оборота этилового спирта, алкогольной и&nbsp;спиртосодержащей продукции&raquo; (вступили в&nbsp;силу с&nbsp;22&nbsp;июля 2011), для получения лицензии на&nbsp;реализацию алкогольной продукции необходимо, чтобы договор аренды помещения был заключён на&nbsp;срок год и&nbsp;более.',
        },
        {
            type: 'circle',
            text: 'В&nbsp;соответствии с&nbsp;нормами гражданского кодекса, указанный договор подлежит государственной регистрации в&nbsp;Управлении Росреестра и&nbsp;вступает в&nbsp;силу с&nbsp;момента его регистрации, а&nbsp;не&nbsp;с&nbsp;момента подписания данного договора.',
        },
        {
            type: 'circle',
            text: 'Таким образом, заключаемый договор аренды должен соответствовать нормам, предъявляемым законодательством о&nbsp;государственной регистрации прав на&nbsp;недвижимое имущество и&nbsp;сделок с&nbsp;ним.',
        },
    ],
}
