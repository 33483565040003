import { Link } from 'react-router-dom';
import { LogoClickIcon } from '../../../assets/icons/icons';
import './Banner.css';

function Banner() {
    return (
        <div className='banner'>
            <div className='banner__title-box'>
                <h2 className='banner__title'>Алкогольная <br />Лицензия</h2>
                <LogoClickIcon
                    className={'banner__logo'}
                    fillClassName={'banner__logo-fill'}
                />
            </div>
            <p className='banner__text'>Получение лицензии на&nbsp;алкоголь будет простым и&nbsp;прозрачным. Вам нужно сделать первый клик!</p>
            <Link className='banner__btn' to={'/services/obtaining-licenses'}>
                Получить лицензию
            </Link>
        </div>
    );
}

export default Banner