import { Link } from 'react-router-dom';
import { CardArrowIcon } from '../../../assets/icons/icons';
import './Services.css';
import { SERVICES_LINKS } from '../../../assets/utils/constants';


function Services() {
    return (
        <div className='services'>
            <h3 className='services__title'>Услуги</h3>
            <div className='services__cards'>
                {SERVICES_LINKS.map((item, i) => (
                    <Link className={`services__card services__cards_type_${i + 1}`} to={`/services/${item.path}`}>
                        <div className='services__card-heading'>
                            <h5 className='services__card-number'>0{i + 1}</h5>
                            <div className='services__card-arrow-box'>
                                <CardArrowIcon
                                    className={'services__card-arrow'}
                                    fillClassName={'services__card-arrow-fill'}
                                />
                            </div>
                        </div>
                        <h4 className='services__card-title'>{item.long_title}</h4>
                        <p 
                         className='services__card-text'
                        dangerouslySetInnerHTML={{
                            __html: item.text,
                        }}></p>
                    </Link>
                ))}
            </div>
        </div>
    );
}

export default Services